// ! Texto Estatico
export const IDIOMA_TEXTO = {
	ES: {
		lang: {
			es: "Español",
			en: "Inglés"
		},
		// Contenido de la barra de navacion
		nav: {
			titulo: "Navegación",
			inicio: "Inicio",
			productos: "Productos",
			estructuras: "Estructuras",
			nosotros: "Nosotros",
			clientes: "Clientes",
			contactenos: "Contáctenos"
		},
		acciones: {
			saber_mas: "Saber más",
			enviar: "Enviar",
			enviado: "Enviado",
			inicio: "Inicio",
			autorizacionSEPS: "Autorización SEPS",
			solicitar_demo: "Solicitar Demo",
			solicitud_anviada: "Solicitud Enviada"
		},
		inicio: {
			portada: {
				lema: "Sistemas informáticos para Bancos, Cooperativas de Ahorro y Crédito, Empresas Comerciales e Industriales."
			},
			modulos: {
				titulo: "Revolucionando los sistemas bancarios",
				parrafo1: "Descubre el pináculo de la automatización en la gestión bancaria con <b>FIT-BANK</b>, el sistema central bancario integrado más completo del mercado. Diseñado a medida para optimizar operaciones, mejorar la rentabilidad y elevar el servicio al cliente, <b>FIT-BANK</b> capacita a las instituciones financieras para lanzar nuevos productos sin esfuerzo y revitalizar su panorama empresarial.",
				parrafo2: "Al analizar meticulosamente tanto los datos financieros como los no financieros, FIT-BANK ofrece conocimientos invaluables sobre la rentabilidad de clientes, productos, sucursales y ejecutivos. Esta comprensión integral permite a las instituciones identificar áreas de éxito y áreas de mejora, facilitando correcciones estratégicas y el desarrollo de productos y servicios personalizados que satisfacen necesidades específicas de los clientes.",
				parrafo3: "Con un sólido enfoque en la gestión de la información del cliente y la excelencia en el servicio, FIT-BANK dota a los ejecutivos comerciales con las herramientas para cultivar relaciones significativas con los clientes. ¿El resultado? Clientes satisfechos y leales que confían implícitamente en su institución, fomentando una disposición a explorar y adoptar nuevas ofertas con confianza.",
				parrafo4: "Experimente el futuro de la gestión bancaria con FIT-BANK y descubra niveles sin precedentes de eficiencia, rentabilidad y satisfacción del cliente."
			},
			clientes: {
				titulo: "Nuestros Clientes",
				parrafo1: "En Soft Warehouse, nos complace haber brindado nuestros productos y servicios a una amplia variedad de cooperativas de ahorró y crédito, bancos y empresas."
			},
			tecnologias: {
				titulo: "Características Incomparables que Redefinen las Operaciones Bancarias",
				parrafo1: "Descubra las capacidades de vanguardia de FIT-BANK, diseñadas meticulosamente para revolucionar la banca en la era digital.",
				web: {
					titulo: "Flexibilidad habilitada para la Web",
					descripcion: "FIT-BANK está completamente habilitado para la web, ofreciendo una arquitectura de tres capas diseñada específicamente para el entorno de Internet. Puede implementarse sin esfuerzo tanto en intranets corporativas para usuarios internos como en internet para clientes y prospectos, garantizando accesibilidad y conveniencia universales."
				},
				compatibilidad: {
					titulo: "Compatibilidad Universal",
					descripcion: "Construido sobre una plataforma universal utilizando Java 1.8 y XML, FIT-BANK es compatible con una amplia gama de sistemas, incluidos IBM S/390, AS/400, RS/6000, SUN Solaris, Hewlett Packard, y plataformas Intel con Windows o Linux. La capa de persistencia, gestionada a través de Hibernate, garantiza una total independencia de base de datos, soportando las principales bases de datos como ORACLE, EnterpriseDB, IBM DB/2, SQL Server, Sybase, PostgreSQL, entre otras."
				},
				multidioma: {
					titulo: "Soporte Multilingüe",
					descripcion: "Adoptando la diversidad, FIT-BANK ofrece soporte multilingüe, permitiendo tanto a usuarios como a clientes interactuar sin esfuerzo en su idioma preferido."
				},
				multicompania: {
					titulo: "Funcionalidad Multicompañía",
					descripcion: "FIT-BANK soporta múltiples empresas de manera fluida, rompiendo barreras con su capacidad multiempresa. Cada empresa opera de manera independiente con sus propios productos y contabilidad, manteniendo perfiles de clientes únicos. Las transacciones entre empresas se ejecutan con estrictos controles y medidas de seguridad."
				},
				producto: {
					titulo: "Creación Dinámica de Productos",
					descripcion: "Empoderando a los usuarios con una flexibilidad sin igual, FIT-BANK cuenta con una herramienta parametrizada para la creación de productos y servicios, permitiendo la introducción fluida de nuevas ofertas y transacciones sin necesidad de amplios conocimientos de programación."
				},
				no_stop: {
					titulo: "Disponibilidad Continua",
					descripcion: "Diseñado para operar de manera ininterrumpida, FIT-BANK garantiza un servicio continuo 24/7, eliminando cualquier proceso que pueda interrumpir el acceso de los usuarios."
				},
				inteligencia: {
					titulo: "Inteligencia de Negocios",
					descripcion: "Equipado con robustas capacidades de inteligencia de negocios, FIT-BANK facilita el análisis multidimensional (OLAP) con más de veinte variables para obtener una visión integral de las operaciones bancarias, productos, monedas, sucursales, ejecutivos, y más."
				},
				reporte: {
					titulo: "Generación de Transacciones y Reportes",
					descripcion: "Simplificando las operaciones, FIT-BANK utiliza un generador de transacciones para un procesamiento de transacciones sin inconvenientes, mientras que los informes se generan a través de Jasper Studio, una herramienta de informes estándar en la industria."
				},
				soporte: {
					titulo: "Soporte Integral de Canales",
					descripcion: "Con la Interfaz Universal de Canales (UCI), FIT-BANK soporta de manera fluida una variedad de canales, incluyendo Banca por Internet, Banca Telefónica, Quioscos, Teléfonos Móviles, y Asistentes Digitales (PDAs), asegurando una experiencia bancaria integrada y versátil."
				},
				seguridad: {
					titulo: "Seguridad y Encriptación Robusta",
					descripcion: "Priorizando la seguridad, FIT-BANK cuenta con métodos de encriptación avanzados y medidas de seguridad robustas que incluyen gestión de usuarios, control de acceso basado en roles, políticas de contraseñas, horarios de trabajo de usuarios, horarios de ejecución de transacciones, montos máximos de transacciones, y soporte para directorio activo y autenticación biométrica."
				},
				auditoria: {
					titulo: "Auditoría e Información Histórica",
					descripcion: "Ofreciendo una ransparencia inigualable, FIT-BANK registra meticulosamente todas las transacciones a través de UCI, garantizando una trazabilidad y auditabilidad completas. Además, implementa una gestión eficiente de datos históricos, preservando registros en línea para un análisis exhaustivo y recuperación ante desastres."
				},
				nube: {
					titulo: "Sistema Bancario Central Preparado para la Nube",
					descripcion: "FIT-BANK acelera tu transformación digital con nuestro sistema bancario central preparado para la nube. Despliega rápidamente y escala sin esfuerzo para mantenerte a la vanguardia en un entorno financiero dinámico."
				}
			},
			nosotros: {
				titulo: "Nosotros",
				parrafo1: "Somos una empresa ecuatoriana con 20 años de experiencia y presencia en el mercado de consultoría tecnológica, desarrollo e implantación de sistemas especializados para el sector financiero Latinoamericano. Brindándomos las herramientas necesarias para competir en un mundo digitalizado y ofrecemos servicios innovadores, eficientes y seguros para nuestros clientes"
			},
			lema: "Más de 21 años de experiencia respaldan nuestra empresa ecuatoriana en el mercado de consultoría tecnológica y desarrollo de sistemas especializados para el sector financiero.",
			contactenos: {
				titulo: "Contáctenos",
				parrafo1: "No dude en ponerse en contacto con nosotros en cualquier momento. Le brindaremos atención tan pronto como sea posible.",
				form: {
					nombre: "Nombre *",
					pertenece: "Institución de pertenecía *",
					cargo: "Cargó profesional *",
					correo: "Correo *",
					mensaje: "Mensaje",
					identificacion: "Cedula o RUC *"
				},
				info: {
					titulo: "Información",
					correo: "Correo",
					ofi_matriz: "Oficina Principal",
					ofi_cuenca: "Oficina Cuenca",
					telefonos: "Numeros Telefónicos",
					horario: "Horarios laborales"
				}
			}
		},
		seccion: {
			modulos: {
				titulo: "Arquitectura Revolucionaria de FIT-BANK",
				parrafo1: "A la vanguardia de la innovación, FIT-BANK cuenta con una estructura en capas meticulosamente diseñada para revolucionar las operaciones bancarias.",
				tabla: {
					titulo: "Sistema Modular. Elija Solo los Módulos que Necesite",
					general: {
						titulo: "MÓDULOS GENERALES",
						canales: "Manejo de canales (UCI)*",
						admin: "Administración y Parameterización*",
						productos: "Definicion dinámica de productos*",
						tasas: "Tasas y tarifarios*",
						facturacion: "Facturación Electrónica Servicios Financiero*",
						gestor: "Gestor Documental",
						seguridad: "Seguridad",
						personas: "Personas (clientes, socios, proveedores, etc.)",
						contabilidad: "Contabilidad financiera Online"
					},
					activo: {
						titulo: "MÓDULOS DE ACTIVOS",
						cuentas: "Cuentas de Bancos*",
						estados: "Reconciliaciones estado de cuenta",
						inversiones_plazo: "Inversiones (Depositos Plazo Fijo)",
						inversiones_fija: "Inversiones (Papel Comercial Renta fija)",
						inversiones_variable: "Inversiones (Títulos Renta Variable)",
						prestamo_pre: "Prestamos preaprobados",
						prestamo_anticipo: "Prestamos Anticipo de sueldos",
						prestamo_linea: "Prestamos totalmente en linea",
						compra: "Confirming (Compra Facturas provedores)",
						credito: "Fabrica de crédito",
						garantias: "Garantias de préstamo",
						cartera: "Manejo y Administración de cartera",
						cobranza: "Cobranzas",
						cartera_titulo: "Administración de Cartera Titularizada",
						venta_cartera: "Venta de cartera de prestamos",
						cartera_vendida: "Administration de cartera vendida",
						compra_cartera: "Compra de cartera",
						cuentas_cobrar: "Cuentas por cobrar"
					},
					pasivo: {
						titulo: "MÓDULOS PASIVOS",
						cuentas: "Cuentas Corrientes",
						chequera: "Chequeras",
						sobregiros: "Sobregiros",
						cuentas_ahorro: "Cuentas de ahorros",
						cuentas_ahorro_pro: "Cuentas de Ahorro programado",
						depositos: "Depositos a Plazo",
						obligaciones: "Obligaciones Financieras",
						cuentas_pagar: "Cuentas por pagar",
						tarjeta_debito: "Tarjeta de débito Autorizados",
						tarjeta_credito: "Tarjeta de crédito Maneja de plásticos",
						atm: "Interface ISO 8583/20022 para ATM",
						conafips: "Interface Financoop/Conafips"
					},
					admin: {
						titulo: "MÓDULOS ADMINISTRATIVOS",
						activos: "Activos Fijos",
						bienes: "Bienes de control",
						suministros: "Control de suministros oficina",
						compras: "Compras y Proveedores",
						nomina: "Nomina de pagos",
						gastos: "Gastos diferidos",
						factura: "Facturacion de servicios no financieros"
					},
					cumplimiento: {
						titulo: "MÓDULOS DE CUMPLIMIENTO",
						control: "Control y Prevencion Lavado Activos",
						judicial: "Providencias Judiciales",
						reportes: "Reportes y Estructuras Regulatorias"
					},
					gerencia: {
						titulo: "MÓDULOS DE GESTIÓN",
						informacion: "Informacion Gerencial",
						crm: "Gestion de la Relacion con Clientes (CRM)",
						presupuesto: "Elaboración y Control de Presupuesto"
					},
					contingencia: {
						titulo: "MÓDULOS DE CONTINGENCIA ",
						garantias: "Garantías Bancarias",
						comercio: "Comercio Exterior",
						credito: "Lineas de crédito"
					},
					servicio: {
						titulo: "MÓDULOS DE SERVICIOS",
						ventanilla: "Servicios de Ventanilla",
						spi: "Transferencias Interbancarias SPI",
						sci: "Cobros Interbancarios SCI",
						compania: "Interface compañía de servicios",
						buro: "Interface Buro de crédito",
						banca: "Interface Banca de segundo piso"
					},
					movil: {
						titulo: "APLICACIONES MÓVILES",
						oficina: "Oficina móvil para usuarios",
						banca_linea: "Banca en línea",
						banca_movil: "Banca móvil",
						tienda: "Tiendas virtuales Banca en Línea",
						billetera: "Billetera electrónica",
						solicitud: "Solicitud desembolsos",
						corresponsal: "Corresponsal no Bancario"
					},
					soporte: {
						titulo: "MÓDULOS DE SOPORTE TÉCNICO",
						proceso: "Proceso de cierre de dia, Resplados",
						recopilacion: "Replicación de la base de datos",
						anonimizacion: "Anonimización de la base de datos"
					}
				},
				structures: [
					{
						titulo: "Universal Channel Interface (UCI)",
						descripcion: "<p>Como la capa más externa, la <b>UCI</b> es un middleware independiente del núcleo, dedicado a gestionar los canales de acceso. Desde cajeros automáticos hasta banca por Internet, banca telefónica, quioscos, e incluso SMS con móviles y PDAs, la <b>UCI</b> garantiza una interacción fluida con el mundo exterior. Maneja las interacciones con los usuarios, las relaciones con los clientes, los protocolos de seguridad y estandariza el acceso al núcleo, proporcionando una interfaz unificada para todos los canales.</p>",
					},
					{		
						titulo: "Capa de Módulos Funcionales",
						descripcion: "<p>Directamente debajo de la UCI se encuentra la <b>capa de módulos funcionales</b>, accesible a través de transacciones iniciadas por cualquier canal compatible. Estos módulos interactúan sin problemas con la base de datos de clientes y los sistemas contables. Este diseño modular facilita la integración sin esfuerzo de nuevos subsistemas, permitiéndoles concentrarse exclusivamente en sus funciones específicas, al tiempo que aprovechan las capacidades integradas de seguridad, interacción con clientes y funcionalidades contables del sistema.</p>",
					},
					{
						titulo: "Subsistemas de Información de Clientes y Contabilidad",
						descripcion: "<p>Estos dos subsistemas son fundamentales para impulsar la eficiencia operativa y el enfoque en el cliente.</p><p>El sistema de información de clientes está estructurado de manera jerárquica, con niveles personalizables según los requisitos del producto. Su principal objetivo es proporcionar información esencial que permita la creación de productos y servicios adaptados a las necesidades del cliente.</p> <p>Paralelamente, el subsistema contable opera independientemente de planes contables predefinidos, funcionando como una herramienta de gestión dinámica que ofrece información financiera en tiempo real para la toma de decisiones informadas. Además, simplifica el cumplimiento normativo al generar informes regulatorios sin esfuerzo.</p>",
					},
					{
						titulo: "Management Information System (MIS)",
						descripcion: "<p>En el núcleo de FIT-BANK se encuentra el <b>Management Information System (MIS)</b>, que actúa como el repositorio central de todos los datos relevantes. Cada subsistema está diseñado meticulosamente para alimentar y almacenar la información pertinente, brindando a la administración de la institución financiera una visión integral. Desde análisis básicos hasta análisis sofisticados, incluyendo Balances Comparativos, Gestión de Portafolios e Índices Económicos, el SIG equipa a los tomadores de decisiones con las herramientas necesarias para la planificación estratégica y la optimización del rendimiento.</p>",
					},
				],
			},
			nosotros: {
				mision: {
					titulo: "Misión",
					parrafo1: "En Soft Warehouse, nos comprometemos a impulsar la transformación digital en el sector financiero. Desarrollamos soluciones tecnológicas avanzadas que permiten a las instituciones financieras abrazar la revolución digital, expandir sus servicios de manera eficiente y cumplir con las demandas cambiantes del mercado. Nuestra misión es proporcionar herramientas y servicios de última generación que simplifiquen las operaciones, fomenten la innovación y generen un crecimiento sostenible para nuestros clientes. Estamos dedicados a facilitar el acceso a servicios financieros de calidad en cualquier momento y en cualquier lugar, contribuyendo así a la creación de un mundo más conectado y digitalmente habilitado"
				},
				vision: {
					titulo: "Visión",
					parrafo1: "Ser líderes en la vanguardia de la transformación digital en el sector financiero a nivel mundial, proporcionando soluciones innovadoras que permitan a las instituciones financieras convertirse en entidades totalmente digitales. Nos esforzamos por ser reconocidos como el socio tecnológico de confianza que empoderar a nuestros clientes para competir eficazmente en un entorno financiero global, brindando comodidad, accesibilidad y eficiencia a sus socios y clientes."
				},
				historia: {
					titulo: "¡Nuestra Historia!",
					parrafo1: "Soft Warehouse S.A. es una empresa ecuatoriana con 20 años de experiencia y presencia en el mercado de consultoría tecnológica, desarrollo e implantación de sistemas especializados para el sector financiero Latinoamericano, hemos prestados servicios e implantado sistemas en cerca de 40 instituciones Financieras en Ecuador y siete países más de Latinoamérica (Ecuador, Perú, Colombia, Panamá, Belice, Puerto Rico, Dominica y Curazao) y en este año hemos culminado la implantación en una institución financiera en el Reino Unido de la Gran Bretaña",
					fundado: "Fundado",
					instituciones: "Instituciones Financieras",
					paises: "Países",
					anios: "Años de Experiencia"
				},
				valores: {
					titulo: "¡Nuestro valores!",
					parrafo1: "¡Frente al futuro financiero, nuestros valores son el ancla de nuestra excelencia.",
					confianza: {
						titulo: "Confianza",
						descripcion: "Construimos relaciones sólidas basadas en la confianza. Sabes que puedes contar con nosotros para ofrecerte soluciones fiables y transparentes."
					},
					creabilidad: {
						titulo: "Credibilidad",
						descripcion: "Nuestra reputación está respaldada por resultados tangibles y testimonios de clientes satisfechos. Cada día, trabajamos incansablemente para mantener nuestra credibilidad y superar las expectativas."
					},
					etica: {
						titulo: "Ética",
						descripcion: "Guiamos nuestras acciones con integridad en todo momento. Nuestra ética inquebrantable es el fundamento de cada decisión que tomamos, asegurando que cada paso que demos sea ético y responsable."
					},
					compromiso: {
						titulo: "Compromiso",
						descripcion: "Estamos comprometidos con tu éxito. No solo ofrecemos soluciones tecnológicas, sino también un compromiso total para adaptarlas a tus necesidades específicas y lograr juntos tus objetivos."
					},
					disponibilidad: {
						titulo: "Disponibilidad",
						descripcion: "Siempre estamos aquí para ti. Respondemos con agilidad y dedicación a tus consultas y necesidades, brindándote un servicio cercano y personalizado en cada etapa."
					},
					seguridad: {
						titulo: "Seguridad",
						descripcion: "Protegemos tus datos y operaciones con medidas de primer nivel. Tu seguridad es nuestra prioridad, y trabajamos constantemente para garantizar que nuestras soluciones cumplan con los más altos estándares de protecció."
					}
				}

			},
			clientes: {
				titulo: "Nuestros clientes",
				parrafo1: "Hemos brindado servicios e implementado sistemas en alrededor de 40 instituciones financieras en Ecuador y siete países más de América Latina (Ecuador, Perú, Colombia, Panamá, Belice, Puerto Rico, Dominica y Curazao)."
			},
			productos: {
				titulo: "Productos",
				parrafo1: "Nuestra solución de software administrativo te brinda un sistema completo para administrar eficientemente todas las operaciones financieras.",
				estructura: {
					titulo: "Estructura",
					parrafo1: " ha sido diseñado y construido en un esquema de capas"
				}
			},
			estructuras: {
				titulo: "Estructuras legales",
				parrafo1: "En Soft Warehouse, nuestros productos de software especializado ayudan a gestionar eficientemente la generación de estructuras de información para el organismo de control o autoridades competentes, con esto las cooperativas de ahorro y crédito, bancos y empresas garantizan el cumplimiento de la normativa vigente. FIT-BANK es la herramienta tecnológica que brinda información oportuna y segura, garantizando la integridad, disponibilidad y confidencialidad de la información.",
			},
			deploy_reques: {
				titulo: "Descubre Fit-Bank en Acción",
				parrafo1: "Experimenta una demostración en vivo personalizada, obtén respuestas a tus preguntas específicas y descubre por qué Fit-Bank es la elección correcta para tu organización.",
				subtitulo: "Prueba Nuestra Demo",
				parrafo2: "Para solicitar una demostración gratuita de Fit-Bank, por favor completa el formulario a continuación con tus datos. En pocos minutos, recibirás un correo electrónico con las credenciales de acceso para la demo.",
				faq: {
					titulo: "Preguntas Frecuentes (FAQ)",
					q1: "¿Cuánto dura la demo de Fit-Bank?",
					r1: "<b>La demo de Fit-Bank dura 24 horas,</b> durante las cuales puedes explorar todas las funciones del software.",
					q2: "¿Necesito instalar algo en mi computadora para usar la demo?",
					r2: "No, <b>Fit-Bank es completamente basado en la web,</b> por lo que solo necesitas un navegador para acceder a la demo.",
					q3: "¿Qué funciones están disponibles en la demo de Fit-Bank?",
					r3: "<b>La demo incluye todas las funciones principales de Fit-Bank,</b> como la automatización de la gestión bancaria, análisis de rentabilidad de clientes, incorporación de clientes, creación de productos, gestión de sucursales, administración de información de clientes, entre otras.",
					q4: "¿Qué tan segura es la demo de Fit-Bank?",
					r4: "<b>La seguridad es nuestra máxima prioridad en Soft Warehouse S.A.</b> Utilizamos cifrado avanzado y cumplimos con todas las regulaciones de seguridad financiera para proteger tus datos.",
					q5: "¿Cómo puedo programar una demostración en vivo personalizada?",
					r5: "Puedes programar una demostración en vivo personalizada completando <b>el formulario de contacto en nuestro sitio web o enviándonos un mensaje por WhatsApp.</b> Nuestro equipo se pondrá en contacto contigo para programar la sesión.",
				}
			}

		},
		footer: {
			frase: "Soluciones inteligentes para un mundo financiero en constante evolución.",
			derechos: " - Todos los derechos reservados."
		},
		error: {
			error_404: {
				parrafo1: "UH OH! Estas perdido.",
				parrafo2: "La página que buscas no existe. ¿Cómo llegaste aquí? Pero puedes hacer clic en el botón de abajo para volver a la página de inicio!",
			},
			error_500: {
				parrafo1: "Error del Servidor",
				parrafo2: "Parece que el servidor no pudo cargar su solicitud ¿Inténtalo otra vez?"
			}
		}
	},
	EN: {
		lang: {
			es: "Spanish",
			en: "English"
		},
		// Contenido de la barra de navacion
		nav: {
			titulo: "Navigation",
			inicio: "Home",
			productos: "Products",
			estructuras: "Structures",
			nosotros: "About us",
			clientes: "Clients",
			contactenos: "Contact Us"
		},
		acciones: {
			saber_mas: "Learn More",
			enviar: "Send",
			enviado: "Sent",
			inicio: "Home",
			autorizacionSEPS: "SEPS Authorization",
			solicitar_demo: "Request Demo",
			solicitud_anviada: "Request Sent"
		},
		inicio: {
			portada: {
				lema: "Computer Systems for Banks, Savings and Credit Cooperatives, Commercial Enterprises, and Industrial Companies."
			},
			modulos: {
				titulo: "Revolutionizing Banking Systems",
				parrafo1: "Discover  the  pinnacle  of  banking  management  automation  with  <b>FIT-BANK</b>, the most comprehensive integrated core banking system on the market. Tailor-made to streamline operations, enhance profitability, and elevate customer service, <b>FIT-BANK</b> empowers financial institutions to launch new products effortlessly and invigorate their business landscape",
				parrafo2: "By meticulously analyzing both financial and non-financial data, FIT-BANK offers invaluable insights into the profitability of customers, products, branches, and executives. This comprehensive understanding allows institutions to pinpoint areas of success and areas for improvement, enabling strategic corrections and the development of bespoke products and services that cater to specific customer needs.",
				parrafo3: "With a robust focus on customer information management and service excellence, FIT-BANK equips commercial executives with the tools to nurture meaningful customer relationships. The result? Satisfied and loyal customers who trust their institution implicitly, fostering a willingness to explore and adopt new offerings with confidence.",
				parrafo4: "Experience the future of banking management with FIT-BANK and unlock unprecedented levels of efficiency, profitability, and customer satisfaction."
			},
			clientes: {
				titulo: "Our Clients",
				parrafo1: "At Soft Warehouse, we are pleased to have provided our products and services to a wide variety of savings and credit cooperatives, banks, and companies."
			},
			tecnologias: {
				titulo: "Unparalleled Features Redefining Banking Operations",
				parrafo1: "Discover the cutting-edge capabilities of FIT-BANK, meticulously engineered to revolutionize banking in the digital age.",
				web: {
					titulo: "Web-Enabled Flexibility",
					descripcion: "FIT-BANK is seamlessly web-enabled, offering a three-layered architecture tailored for the Internet environment. It can be effortlessly deployed on both corporate intranets for internal users and the internet for clients and prospects, ensuring universal accessibility and convenience."
				},
				compatibilidad: {
					titulo: "Universal Compatibility",
					descripcion: "Built on a universal platform using Java 1.8 and XML, FIT-BANK is compatible with a wide array of systems including IBM S/390, AS/400, RS/6000, SUN Solaris, Hewlett Packard, and Intel Windows or Linux platforms. The persistence layer, managed through Hibernate, guarantees total database independence, supporting major databases such as ORACLE, EnterpriseDB, IBM DB/2, SQL Server, Sybase, PostgreSQL, and more."
				},
				multidioma: {
					titulo: "Multilingual Support",
					descripcion: "Embracing diversity, FIT-BANK offers multilingual support, enabling both users and clients to interact in their preferred language effortlessly."
				},
				multicompania: {
					titulo: "Multi-company Functionality",
					descripcion: "Seamlessly supporting multiple companies, FIT-BANK breaks boundaries with its multi-company capability. Each company operates independently with its own products and accounting, while maintaining unique client profiles. Inter-company transactions are executed with stringent controls and security measures in place."
				},
				producto: {
					titulo: "Dynamic Product Creation",
					descripcion: "Empowering users with unparalleled flexibility, FIT-BANK features a parametrized product and service creation tool, allowing for the seamless introduction of new offerings and transactions without the need for extensive programming knowledge."
				},
				no_stop: {
					titulo: "Continuous Availability",
					descripcion: "Designed for non-stop operation, FIT-BANK ensures uninterrupted service 24/7, eliminating any processes that may disrupt user access."
				},
				inteligencia: {
					titulo: "Business Intelligence",
					descripcion: "Equipped with robust business intelligence capabilities, FIT-BANK facilitates multidimensional analysis (OLAP) with over twenty variables for comprehensive insights across banking operations, products, currencies, branches, executives, and more."
				},
				reporte: {
					titulo: "Transaction and Report Generation",
					descripcion: "Streamlining operations, FIT-BANK utilizes a transaction generator for seamless transaction processing, while reports are generated through Jasper Studio, an industry-standard reporting tool."
				},
				soporte: {
					titulo: "Comprehensive Channel Support",
					descripcion: "With Universal Channel Interface (UCI), FIT-BANK seamlessly supports a variety of channels including Internet Banking, Telephone Banking, Kiosks, Mobile Phones, and Digital Assistants (PDAs), ensuring an integrated and versatile banking experience."
				},
				seguridad: {
					titulo: "Robust Security and Encryption",
					descripcion: "Prioritizing security, FIT-BANK features advanced encryption methods and robust security measures including user management, role-based access control, password policies, user work schedules, transaction execution schedules, maximum transaction amounts, and support for active directory and biometric authentication."
				},
				auditoria: {
					titulo: "Audit and Historical Information",
					descripcion: "Offering unparalleled transparency, FIT-BANK meticulously logs all transactions through UCI, ensuring complete traceability and auditability. Moreover, it implements efficient historical data management, preserving records online for comprehensive analysis and disaster recovery."
				},
				nube: {
					titulo: "Cloud-ready Core Banking System",
					descripcion: "FIT-BANK Accelerate your digital transformation with our cloud-ready core banking system. Deploy rapidly and scale effortlessly to stay ahead in a dynamic financial landscape."
				}
			},
			nosotros: {
				titulo: "About Us",
				parrafo1: "We are an Ecuadorian company with 20 years of experience and a presence in the technological consulting market, specializing in the development and implementation of specialized systems for the Latin American financial sector. Providing the necessary tools to compete in a digitized world, we offer innovative, efficient, and secure services for our clients."
			},
			lema: "With over 21 years of experience, our Ecuadorian company is backed by a strong presence in the technological consulting market and the development of specialized systems for the financial sector.",
			contactenos: {
				titulo: "Contact Us",
				parrafo1: "Feel free to reach out to us anytime. We will provide assistance as soon as possible.",
				form: {
					nombre: "Name *",
					pertenece: "Institution of belonging *",
					cargo: "Professional position *",
					correo: "Email *",
					mensaje: "Message",
					identificacion: "ID or RUC *"
				},
				info: {
					titulo: "Information",
					correo: "Email",
					ofi_matriz: "Matrix Office",
					ofi_cuenca: "Cuenca Office",
					telefonos: "Phone numbers",
					horario: "Office Hours"
				}
			}
		},
		seccion: {
			modulos: {
				titulo: "Revolutionary Architecture of FIT-BANK",
				parrafo1: "At the forefront of innovation, FIT-BANK boasts a meticulously crafted layered structure designed to revolutionize banking operations.",
				tabla: {
					titulo: "Modular System. Choose Only The Modules You Need",
					general: {
						titulo: "GENERAL MODULES",
						canales: "Universal Channel Interface (UCI)*",
						admin: "General Administration and Parameterization",
						productos: "Dynamic Product Definition*",
						tasas: "Interest rates and fees*",
						facturacion: "Electronic Invoicing for Financial Services*",
						gestor: "Document Management",
						seguridad: "Security",
						personas: "People (Clients, partners, Users, Suppliers)*",
						contabilidad: "Online Financial Accounting"
					},
					activo: {
						titulo: "ASSETS MODULES",
						cuentas: "Nostro Accounts*",
						estados: "Bank Reconciliations",
						inversiones_plazo: "Investments (Institution's Fixed-term Deposits)",
						inversiones_fija: "Investments (Fixed-Income Securities)",
						inversiones_variable: "Investments: Variable-Income Securities",
						prestamo_pre: "Pre-approved Loans",
						prestamo_anticipo: "Pre-approved Salary Advance",
						prestamo_linea: "Fully Online Loan",
						compra: "Confirming (Financing clients' purchases)",
						credito: "Credit Factory",
						garantias: "Loan Guarantees",
						cartera: "Loan Portfolio Management",
						cobranza: "Collections",
						cartera_titulo: "Administration of Securitized Portfolio",
						venta_cartera: "Loan Portfolio Purchase",
						cartera_vendida: "Administration of Sold Portfolio",
						compra_cartera: "Portfolio Purchase",
						cuentas_cobrar: "Accounts Receivable"
					},
					pasivo: {
						titulo: "PASSIVE MODULES",
						cuentas: "Current Accounts",
						chequera: "Checkbooks for current accounts",
						sobregiros: "Overdrafts for current accounts",
						cuentas_ahorro: "Savings Accounts",
						cuentas_ahorro_pro: "Scheduled Savings Accounts",
						depositos: "Time Deposits",
						obligaciones: "Financial Obligations (Liability Loans)",
						cuentas_pagar: "Accounts Payable",
						tarjeta_debito: "Debit Card Authorizer",
						tarjeta_credito: "Debit Card Plastics Management Managing",
						atm: "ISO 8583/20022 Interface With ATM",
						conafips: "Interface Financoop/Conafips"
					},
					admin: {
						titulo: "ADMINISTRATIVE MODULES",
						activos: "Fixed Assets",
						bienes: "Controlled Assets",
						suministros: "Supply Inventory",
						compras: "Suppliers and Purchases",
						nomina: "Payroll",
						gastos: "Deferred Expenses",
						factura: "Invoicing Non-Financial Services"
					},
					cumplimiento: {
						titulo: "COMPLIANCE MODULES",
						control: "Control Prevention of Money Laundering",
						judicial: "Judicial Proceedings",
						reportes: "Reports: Legal and Regulatory"
					},
					gerencia: {
						titulo: "MANAGEMENT MODULES",
						informacion: "Management Information",
						crm: "Customer Relationship Management (CRM)",
						presupuesto: "Budget"
					},
					contingencia: {
						titulo: "CONTINGENT MODULES",
						garantias: "Loan Guarantees",
						comercio: "Foreign trade",
						credito: "Credit Lines"
					},
					servicio: {
						titulo: "SERVICES MODULES",
						ventanilla: "Cashier Services",
						spi: "Wire Transfers",
						sci: "Interbank Collection System via Central Bank",
						compania: "Interface with companies for service payments",
						buro: "Credit Bureau Interface",
						banca: "Interface with Second-tier Institutions"
					},
					movil: {
						titulo: "MOBILE APPLICATIONS",
						oficina: "Mobile Office for Users",
						banca_linea: "Online Banking",
						banca_movil: "Mobile Banking *",
						tienda: "Virtual Stores for Online Banking",
						billetera: "Mobile Wallet",
						solicitud: "Credit Line Disbursements",
						corresponsal: "Non-Banking Correspondent"
					},
					soporte: {
						titulo: "TECHNICAL SUPPORT MODULES",
						proceso: "End of day closing and backup",
						recopilacion: "Database Replication",
						anonimizacion: "Database anonimyzation"
					}
				},
				structures: [
					{
						titulo: "Universal Channel Interface (UCI)",
						descripcion: "<p>Serving as the outermost layer, <b>UCI</b> is a Core-independent Middleware dedicated to managing access channels. From ATMs to Internet banking, Telephone Banking to Kiosks, and even SMS with Mobiles and PDAs, <b>UCI</b> ensures seamless interaction with the external world. It handles user interactions, client engagements, security protocols, and standardizes access to the Core, providing a unified interface for all channels.</p>",
					},
					{		
						titulo: "Functional Modules Layer",
						descripcion: "<p>Directly below UCI lies <b>the functional modules layer</b>, accessible through transactions initiated via any supported channel. These modules seamlessly interact with the customer database and accounting systems. This modular design facilitates the effortless integration of new subsystems, allowing them to focus solely on their specific functions while leveraging the system's built-in security, customer interaction capabilities, and accounting functionalities.</p>",
					},
					{
						titulo: "Customer Information and Accounting Subsystems",
						descripcion: "<p>Serving as the backbone of the system, these two subsystems are pivotal in driving operational efficiency and customer-centricity.</p><p>The customer information system is structured hierarchically, with customizable levels tailored to product requirements. Its primary objective is to furnish essential insights, enabling the creation of tailored products and services to meet customer needs.</p><p>Concurrently, the accounting subsystem operates independently of predefined account plans, functioning as a dynamic management tool that delivers real-time financial information for informed decision-making. Moreover, it simplifies compliance by generating regulatory reports effortlessly.</p>",
					},
					{
						titulo: "Management Information System (MIS)",
						descripcion: "<p>At the heart of FIT-BANK lies the <b>Management Information System (MIS)</b>, serving as the central repository for all relevant data. Each subsystem is intricately designed to feed and store pertinent information, empowering financial institution management with comprehensive insights. From basic analytics to sophisticated analyses including Comparative Balances, Portfolio Management, and Economic Indices, the MIS equips decision-makers with the tools necessary for strategic planning and performance optimization.</p>",
					},
				],
			},
			nosotros: {
				mision: {
					titulo: "Mission",
					parrafo1: "At Soft Warehouse, we are committed to driving digital transformation in the financial sector. We develop advanced technological solutions that enable financial institutions to embrace the digital revolution, expand their services efficiently, and meet the changing demands of the market. Our mission is to provide cutting-edge tools and services that simplify operations, foster innovation, and generate sustainable growth for our clients. We are dedicated to facilitating access to quality financial services anytime, anywhere, thereby contributing to the creation of a more connected and digitally empowered world."
				},
				vision: {
					titulo: "Vision",
					parrafo1: "To be leaders at the forefront of digital transformation in the global financial sector, providing innovative solutions that enable financial institutions to become fully digital entities. We strive to be recognized as the trusted technological partner that empowers our clients to compete effectively in a global financial environment, delivering convenience, accessibility, and efficiency to their partners and customers."
				},
				historia: {
					titulo: "¡Our Story!",
					parrafo1: "Soft Warehouse S.A. is an Ecuadorian company with 20 years of experience and a presence in the technological consulting market, specializing in the development and implementation of systems for the Latin American financial sector. We have provided services and implemented systems in around 40 financial institutions in Ecuador and seven other countries in Latin America (Ecuador, Peru, Colombia, Panama, Belize, Puerto Rico, Dominica, and Curacao). This year, we have successfully completed the implementation in a financial institution in the United Kingdom of Great Britain.",
					fundado: "Founded",
					instituciones: "Financial Institutions",
					paises: "Countries",
					anios: "Years of Experience"
				},
				valores: {
					titulo: "¡Our Values!",
					parrafo1: "In the face of the financial future, our values are the anchor of our excellence.",
					confianza: {
						titulo: "Trust",
						descripcion: "We build strong relationships based on trust. You know you can count on us to provide reliable and transparent solutions."
					},
					creabilidad: {
						titulo: "Credibility",
						descripcion: "Our reputation is supported by tangible results and testimonials from satisfied clients. Every day, we work tirelessly to maintain our credibility and exceed expectations."
					},
					etica: {
						titulo: "Ethics",
						descripcion: "We guide our actions with integrity at all times. Our unwavering ethics form the foundation of every decision we make, ensuring that every step we take is ethical and responsible."
					},
					compromiso: {
						titulo: "Commitment",
						descripcion: "We are committed to your success. We not only provide technological solutions but also a total commitment to tailor them to your specific needs and achieve your goals together."
					},
					disponibilidad: {
						titulo: "Availability",
						descripcion: "We are always here for you. We respond promptly and dedicatedly to your inquiries and needs, providing you with close and personalized service at every stage."
					},
					seguridad: {
						titulo: "Security",
						descripcion: "We safeguard your data and operations with top-notch measures. Your security is our priority, and we continuously work to ensure that our solutions meet the highest standards of protection."
					}
				}

			},
			clientes: {
				titulo: "Our Clients",
				parrafo1: "We have provided services and implemented systems in around 40 financial institutions in Ecuador and seven more countries in Latin America (Ecuador, Peru, Colombia, Panama, Belize, Puerto Rico, Dominica, and Curacao)."
			},
			productos: {
				titulo: "Products",
				parrafo1: "Our administrative software solution provides you with a comprehensive system to efficiently manage all financial operations.",
				estructura: {
					titulo: "Structure",
					parrafo1: " has been designed and built in a layered scheme"
				}
			},
			estructuras: {
				titulo: "Legal Structures",
				parrafo1: "At Soft Warehouse, our specialized software products help efficiently manage the generation of information structures for regulatory bodies or competent authorities. This ensures that credit unions, banks, and companies comply with current regulations. FIT-BANK is the technological tool that provides timely and secure information, guaranteeing the integrity, availability, and confidentiality of the information.",
			},
			deploy_reques: {
				titulo: "Discover Fit-Bank in Action",
				parrafo1: "Experience a personalized live demonstration, get answers to your specific questions, and discover why Fit-bank is the right choice for your organization",
				subtitulo: "Try Our Demo",
				parrafo2: "To request a free demonstration of Fit-Bank, please fill out the form below with your details. Within a few minutes, you will receive an email with the access credentials for the demo.",
				faq: {
					titulo: "Frequently Asked Questions (FAQ)",
					q1: "How long does the Fit-Bank demo last?",
					r1: "<b>The Fit-Bank demo lasts for 24 hours,</b> during which you can explore all the software's features.",
					q2: "Do I need to install anything on my computer to use the demo?",
					r2: "No, <b>Fit-Bank is completely web-based</b>, so you only need a browser to access the demo.",
					q3: "What features are available in the Fit-Bank demo?",
					r3: "<b>The demo includes all the main features of Fit-Bank,</b> such as banking management automation, customer profitability analysis, customer onboarding, product creation, branch management, customer information administration, among others.",
					q4: "How secure is the Fit-Bank demo?",
					r4: "<b>Security is our top priority at Soft Warehouse S.A.</b> We use advanced encryption and comply with all financial security regulations to protect your data.",
					q5: "How can I schedule a personalized live demonstration?",
					r5: "You can schedule a personalized live demonstration by filling out <b>the contact form on our website or by messaging us on WhatsApp.</b> Our team will get in touch with you to schedule the session.",
				}
			}
		},
		footer: {
			frase: "Intelligent Solutions for an Ever-Evolving Financial World.",
			derechos: " - All rights reserved."
		},
		error: {
			error_404: {
				parrafo1: "Uh oh! You're lost.",
				parrafo2: "The page you're looking for doesn't exist. How did you get here? But you can click the button below to go back to the homepage!",
			},
			error_500: {
				parrafo1: "Server Error",
				parrafo2: "It looks like the server couldn't load your request. Would you like to try again?"
			}
		}
	}
}
